<template>
  <div class="order-cast" id="order-cast">
    <AppContainer :useBackground="true">
      <card-header-body ref="header" :node="headerTitle" />
      <div id="content-scroll" class="content-scroll">
        <template v-if="isLoading">
          <AppLoading />
        </template>
        <template v-else>
          <div class="order__list">
            <template v-if="(castList.designations || []).length > 0">
              <div class="d-flex flex-wrap">
                <template v-for="(cast, index) in castList.designations">
                  <div
                    class="order__item"
                    :key="'designations_' + index"
                    :class="
                      isDisabled ||
                      (getNumberSelected() === maxCast && !cast.isSelected) ||
                      cast.status !== STATUS_ORDER.AVAILABLE
                        ? 'disabled'
                        : ''
                    "
                  >
                    <b-list-group style="max-width: 300px">
                      <b-list-group-item class="d-flex align-items-center">
                        <b-form-checkbox
                          :class="
                            isDisabled ||
                            (getNumberSelected() === maxCast &&
                              !cast.isSelected) ||
                            cast.status !== STATUS_ORDER.AVAILABLE
                              ? 'd-none'
                              : 'd-flex'
                          "
                          v-model="cast.isSelected"
                          class="f-w3 check-box align-items-center"
                        />
                        <b-avatar
                          variant="info"
                          :src="cast.image_url"
                          class="mr-3"
                          button
                          @click.prevent="redirectProfile(cast.user_id)"
                        ></b-avatar>
                        <div class="info d-flex">
                          <div class="d-flex">
                            <div
                              :class="
                                cast.status === STATUS_ORDER.AVAILABLE
                                  ? 'online'
                                  : ''
                              "
                              class="status d-flex f-w3"
                            >
                              <!-- <span class="dot" style="margin-top: 5px"></span> -->
                              <div
                                class="d-flex flex-column justify-content-start"
                                style="margin-right: 15px"
                              >
                                <p style="margin-bottom: 10px">
                                  <!-- {{ STATUS_ORDER_TEXT[cast.status] }} -->
                                  {{ cast.female_prego_rank_name }}
                                </p>
                                <p
                                  class="
                                    text
                                    mr-auto
                                    f-w3
                                    text-nickname
                                    mb-0
                                    mr-0
                                  "
                                  style="
                                    margin-right: 0px !important;
                                    font-size: 14px;
                                  "
                                  @click.prevent="redirectProfile(cast.user_id)"
                                >
                                  {{ cast.nickname }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex flex-column justify-content-start">
                            <!-- <p class="rank f-w3" style="margin-bottom: 10px">
                              {{ cast.female_prego_rank_name }}
                            </p> -->
                            <div
                              class="
                                mark
                                d-flex
                                align-items-center
                                justify-content-center
                                f-w3
                              "
                            >
                              個人オファー
                            </div>
                            <p class="text f-w3 mb-0" style="font-size: 14px">
                              {{ cast.age }}歳
                            </p>
                          </div>
                        </div>
                        <b-icon
                          v-if="cast.is_favorite"
                          icon="star-fill"
                          @click="changeLike(cast)"
                        ></b-icon>
                        <b-icon
                          v-else
                          icon="star"
                          @click="changeLike(cast)"
                        ></b-icon>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </template>
              </div>
            </template>
            <template v-if="(castList.applicants || []).length > 0">
              <div class="sub-title f-w3 txt-left">
                ※タイプ、スコア、ポイント等、異なる可能性があり
                ます。プロフィールを必ず確認し、承認してください。
              </div>
              <div class="d-flex flex-wrap">
                <template v-for="(cast, index) in castList.applicants">
                  <div
                    class="order__item"
                    :class="
                      isDisabled ||
                      (getNumberSelected() === maxCast && !cast.isSelected) ||
                      cast.status !== STATUS_ORDER.AVAILABLE
                        ? 'disabled'
                        : ''
                    "
                    :key="'applicants_' + index"
                  >
                    <b-list-group style="max-width: 300px">
                      <b-list-group-item class="d-flex align-items-center">
                        <b-form-checkbox
                          :class="
                            isDisabled ||
                            (getNumberSelected() === maxCast &&
                              !cast.isSelected) ||
                            cast.status !== STATUS_ORDER.AVAILABLE
                              ? 'd-none'
                              : 'd-flex'
                          "
                          v-model="cast.isSelected"
                          class="f-w3 check-box align-items-center"
                        />
                        <b-avatar
                          variant="info"
                          :src="cast.image_url"
                          class="mr-3"
                          button
                          @click.prevent="redirectProfile(cast.user_id)"
                        ></b-avatar>
                        <div class="info">
                          <div class="rank f-w3">
                            {{ cast.female_prego_rank_name }}
                          </div>
                          <span
                            class="text mr-auto f-w3 text-nickname"
                            @click.prevent="redirectProfile(cast.user_id)"
                            >{{ cast.nickname }}</span
                          >
                          <span class="text f-w3">{{ cast.age }}歳</span>
                        </div>
                        <b-icon
                          v-if="cast.is_favorite"
                          icon="star-fill"
                          @click="like(cast)"
                        ></b-icon>
                        <b-icon v-else icon="star" @click="like(cast)"></b-icon>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </template>
              </div>
            </template>
            <template v-if="!checkData">
              <p class="f-w6 text-center">現在オファーはありません。</p>
            </template>
            <p
              class="f-w3"
              v-if="
                (castList.applicants || []).length <= 0 &&
                  (castList.designations || []).length <= 0
              "
            >
              現在応募中の女性はいません。
            </p>
          </div>
          <div v-if="!isDisabled" class="btn-submit">
            <button
              :disabled="
                isDisabled || Number(getNumberSelected()) !== Number(maxCast)
              "
              @click="matchingOrder()"
              class="f-w6"
            >
              確定({{ getNumberSelected() }}/{{ maxCast }})
            </button>
          </div>
          <ModalLayoutOrderCast ref="modal-maching-cast">
            <div class="twinkle">
              <img src="@/assets/image/twinkle_text.svg" />
              <p class="f-w3 text-center mb-0 text-note">キャンセル料は</p>
              <p class="f-w3 text-center mb-0 text-note">
                開催日から2日まで100％
              </p>
              <p class="f-w3 text-center mb-0 text-note">
                開催日3日～7日前まで50％
              </p>
              <p class="f-w3 text-center mb-0 text-note">
                開催日8日以降特になし
              </p>
              <p class="f-w3 text-center mb-0 text-note">
                になっております。詳しくは<span
                  class="text-faq"
                  @click="$router.push({ name: 'MenFAQ' })"
                  >こちら</span
                >をご覧ください
              </p>
            </div>
            <!-- <p class="f-w3 maching-order--title">
              マッチングを確定いたします。
            </p>
            <div class="d-flex maching-order-btn justify-content-center f-w3">
              <button class="btn-confirm" @click="orderCast()">はい</button>
              <button
                class="btn-cancel"
                @click="$refs['modal-maching-cast'].closeModal()"
              >
                いいえ
              </button>
            </div> -->
          </ModalLayoutOrderCast>
        </template>
      </div>
      <CardFooter-body ref="footer" />
      <ModalLayout ref="modal-confirm-maching">
        <p class="modal-logout-title f-w3">
          マッチングが成立します。ポイントが決済されますがよろしいですか。
        </p>
        <div class="d-flex justify-content-center">
          <button @click.prevent="orderCast" class="btn-logout">はい</button>
          <button
            @click="$refs['modal-confirm-maching'].closeModal()"
            class="btn-cancel-logout"
          >
            いいえ
          </button>
        </div>
      </ModalLayout>
    </AppContainer>
  </div>
</template>

<script>
import CardHeader from "../../components/CardHeader";
import CardFooter from "../../components/CardFooter";
import { mapActions, mapGetters } from "vuex";
import service from "@/utils/axios";
import { USER_URL } from "@/api/api";
import { STATUS_ORDER, STATUS_ORDER_TEXT } from "@/utils/const";
import ModalLayoutOrderCast from "@/views/Common/ModalLayoutOrderCast";
// import { MALE_GENDER, FEMALE_GENDER } from "@/definition/constants";
// import { GROUP_ROOM_TYPE } from "@/constants/message";

export default {
  name: "CastSearch",
  // props: {
  //   adId: {
  //     type: Number
  //   },
  //   maxCast: {
  //     type: Number
  //   },
  //   isDisabled: {
  //     type: Boolean
  //   }
  //   // profileId: {
  //   //   type: [Number, null]
  //   // }
  // },
  components: {
    "card-header-body": CardHeader,
    "CardFooter-body": CardFooter,
    ModalLayoutOrderCast
  },
  metaInfo() {
    return {
      title: "応募中リスト",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      adId: null,
      maxCast: null,
      isDisabled: false,
      total: 0,
      isExpand_order: false,
      headerTitle: {
        text: "応募中リスト",
        isShowTitle: true,
        isShowLogo: false
        // back: null,
        // backTo: this.profileId
        //   ? {
        //       name: "MenOrderList",
        //       params: { profileId: this.profileId }
        //     }
        //   : null
      },
      members: [],
      users: [],
      STATUS_ORDER,
      STATUS_ORDER_TEXT
    };
  },
  methods: {
    ...mapActions("firebase", ["createRoom"]),

    like(data) {
      service({
        url: USER_URL + "/favorite",
        method: "POST",
        data: {
          user_id: data.user_id,
          is_favorite: data.is_favorite === 1 ? 0 : 1
        }
      }).then(response => {
        const item = this.castList.applicants.find(
          item => item.user_id === data.user_id
        );

        if (response.data.success === true) {
          item.is_favorite = data.is_favorite === 1 ? 0 : 1;
        }
      });
    },

    changeLike(data) {
      service({
        url: USER_URL + "/favorite",
        method: "POST",
        data: {
          user_id: data.user_id,
          is_favorite: data.is_favorite === 1 ? 0 : 1
        }
      }).then(response => {
        const item = this.castList.designations.find(
          item => item.user_id === data.user_id
        );

        if (response.data.success === true) {
          item.is_favorite = data.is_favorite === 1 ? 0 : 1;
        }
      });
    },

    getNumberSelected() {
      return (
        this.castList.designations.filter(cast => cast.isSelected).length +
        this.castList.applicants.filter(cast => cast.isSelected).length
      );
    },
    matchingOrder() {
      if (
        Number(this.total) == 0 &&
        Number(this.$route.query.designation_length) > 0 &&
        !this.isExpand_order
      ) {
        this.orderCast();
        return;
      }
      this.$refs["modal-confirm-maching"].openModal();
    },
    redirectProfile(userId) {
      this.$router.push({
        path: `/profile/${userId}`
      });
    },
    openModalOrderCast() {},
    orderCast() {
      this.$refs["modal-confirm-maching"].closeModal();
      // this.$refs["modal-maching-cast"].closeModal();
      const arrUser = [];
      // let sex;
      // if (this.user.sex === MALE_GENDER) {
      //   sex = FEMALE_GENDER;
      // } else if (this.user.sex === FEMALE_GENDER) {
      //   sex = MALE_GENDER;
      // }
      this.castList.applicants.forEach(el => {
        if (el.isSelected) {
          arrUser.push(el.user_id);
          // this.members.push({
          //   userId: el.user_id,
          //   isHidden: false,
          //   createdAt: new Date()
          // });
          // this.users.push({
          //   id: el.user_id,
          //   data: {
          //     nickname: el.nickname,
          //     sex: sex,
          //     imageUrl: el.image_url
          //   }
          // });
        }
      });
      this.castList.designations.forEach(el => {
        if (el.isSelected) {
          arrUser.push(el.user_id);
          // this.members.push({
          //   userId: el.user_id,
          //   isHidden: false,
          //   createdAt: new Date()
          // });
          // this.users.push({
          //   id: el.user_id,
          //   data: {
          //     nickname: el.nickname,
          //     sex: sex,
          //     imageUrl: el.image_url
          //   }
          // });
        }
      });
      this.$root.$refs.loading.start();
      this.$store
        .dispatch("orderManagement/orderCast", {
          user_id: this.user.user_id,
          ad_id: this.adId,
          user_ids: arrUser
        })
        .then(() => {
          this.$root.$refs.loading.finish();
          this.$refs["modal-maching-cast"].openModal();
          // let data = {
          //   users: this.users,
          //   members: this.members,
          //   adId: this.adId,
          //   type: GROUP_ROOM_TYPE
          // };
          // this.$store
          //   .dispatch("firebase/createRoom", data)
          //   .then(() => {
          //     this.$root.$refs.loading.finish();
          //   })
          //   .catch(() => {
          //     this.$root.$refs.loading.finish();
          //   });
          // this.$router.push({
          //   name: "MenOrderList",
          //   query: { order: "success" }
          // });
          // this.$toast(MESSAGES.SAVE_SUCCESS, "通知", "success");
          // this.$root.$refs.loading.finish();
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
          // this.$toast(error.response.data.error.message, "通知", "danger");
        });
    },
    filterData(data) {
      let passData = data.filter(item => item.status !== STATUS_ORDER.CANCELED);

      return passData;
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      castList: "orderManagement/castList",
      isLoading: "common/isLoading"
    }),
    checkData() {
      let check = true;
      if (
        this.castList.designations === 0 &&
        this.castList.applicants.length === 0
      ) {
        check = false;
      }

      return check;
    }
  },
  async created() {
    // this.members.push({
    //   userId: this.user.user_id,
    //   isHidden: false,
    //   createdAt: new Date()
    // });
    // this.users.push({
    //   id: this.user.user_id,
    //   data: {
    //     nickname: this.user.nickname,
    //     sex: this.user.sex,
    //     imageUrl: ""
    //   }
    // });
    this.adId = this.$route.query.adId;
    this.maxCast = this.$route.query.maxCast;
    this.total = this.$route.query.total;
    this.adStatus = this.$route.query.adStatus;
    if (this.adStatus >= 3) {
      this.headerTitle.text = "確定済み";
    }
    if (
      this.$route.query.isDisabled == true ||
      this.$route.query.isDisabled == "true"
    ) {
      this.isDisabled = true;
    }
    if (
      this.$route.query.isExpand_order == true ||
      this.$route.query.isDisabled == "true"
    ) {
      this.$route.query.isExpand_order = true;
    }
    if (!this.adId) {
      this.$router.push({ name: "MenOrderList" });
      return;
    }
    this.$store.dispatch("common/setIsLoading", true);
    await this.$store
      .dispatch("orderManagement/getCastList", this.adId)
      .then(() => {
        this.$store.dispatch("common/setIsLoading", false);
      })
      .catch(() => {
        this.$store.dispatch("common/setIsLoading", false);
      });
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === "MenFAQ") {
        vm.$router.push({
          name: "MenOrderList"
        });
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.content-scroll {
  // overflow-y: scroll;
  padding-top: 40px;
  padding-bottom: 100px;
  overflow-x: hidden;
  min-height: 100vh;
}
@media screen and (min-width: 1200px) {
  .content-scroll {
    // height: calc(100vh - 152px);
    // height: calc(100vh);
    padding-top: 60px;
  }
}
</style>

<style lang="scss">
@import "../../assets/scss/men/orderCast.scss";

.text-faq {
  color: #0178fd;
  text-decoration: underline;
  cursor: pointer;
}

.twinkle {
  background: url("~@/assets/image/twinkle_background.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center top;
  margin: 37px 0;
  p {
    text-align: center;
    font-size: 14px;
  }
  img {
    margin-top: 29px;
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .twinkle {
    margin: 51px 0;
    p {
      font-size: 16px;
    }
    img {
      margin-top: 40px;
    }
  }
}
</style>
